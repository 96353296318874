import { FC, CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppText, Heading } from "components";
import { MainButton } from "components/Buttons";
import { useMediaQuery } from "helpers/hooks";
import routesPaths from "routes/routesPaths";
import { colors } from "helpers/consts";

import verifyImage from "assets/images/imoon/verify.png";
import { ReactComponent as ClipboadrCheckIcon } from "assets/icons/clipboard-check.svg";

interface VerificationWarningProps {
  text: string;
  withPadding?: boolean;
  withMargin?: boolean;
  style?: CSSProperties;
}
const VerificationWarning: FC<VerificationWarningProps> = ({
  text,
  withPadding,
  withMargin,
  style,
}) => {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(min-width: 24em)");

  return (
    <MainContainer
      $withPadding={withPadding}
      style={style || {}}
      $withMargin={withMargin}
    >
      <WarningContainer>
        <WarningContent>
          {mobile && <img src={verifyImage} alt="decoration" />}
          <StaticWarningInfo>
            <Heading variant="h3" style={{ marginBottom: "0.8rem" }}>
              Account Verification Required
            </Heading>
            <AppText color={colors.gray_700}>{text}</AppText>
          </StaticWarningInfo>
        </WarningContent>
        <MainButton onClick={() => navigate(routesPaths.verification)}>
          <ClipboadrCheckIcon />
          Verify account
        </MainButton>
      </WarningContainer>
    </MainContainer>
  );
};

interface WarningContainerInterface {
  $withPadding?: boolean;
  $withMargin?: boolean;
}

const MainContainer = styled.div<WarningContainerInterface>`
  width: 100%;
  padding: ${({ $withPadding }) => ($withPadding ? "0 1.6rem" : "0 0")};
  ${({ $withMargin }) => $withMargin && "margin-bottom: 1rem;"}

  @media (min-width: 34em) {
    padding: ${({ $withPadding }) => ($withPadding ? "0 2.4rem" : "0 0")};
    ${({ $withMargin }) => $withMargin && "margin-bottom: 1.6rem;"}
  }
`;
const WarningContainer = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: ${colors.error_50};
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 1.8rem;
  border: solid 1px ${colors.primary_200};

  & > button {
    min-width: 18rem;
    margin: 0.8rem 0 0 0;
  }

  @media (min-width: 34em) {
    padding: 1.6rem;
    flex-wrap: nowrap;
    flex-direction: row;

    & > button {
      margin: 0 0 0 1.6rem;
    }
  }
`;

const WarningContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  & > img {
    max-width: 5.3rem;
    min-width: 5.3rem;
    max-height: 5rem;
    min-height: 5rem;
    margin-right: 0.8rem;
  }

  @media (min-width: 34em) {
    & > img {
      margin-right: 1.6rem;
    }
  }
`;

const StaticWarningInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default VerificationWarning;
