import { ReactNode } from "react";
import styled from "styled-components";
import { ItezFiatOptionVariants, ItezFiatOption } from "helpers/types";

import { ReactComponent as Usd } from "assets/icons/currencies/USD.svg";
import { ReactComponent as Eur } from "assets/icons/currencies/EUR.svg";
import { ReactComponent as CurrencyZone } from "assets/icons/currencies/CurrencyZone.svg";

interface StyledCustomIconInterface {
  $extraSmall?: boolean;
  $small?: boolean;
  $medium?: boolean;
}
const StyledCustomIcon = styled.div<StyledCustomIconInterface>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  min-width: 4.8rem;
  max-width: 4.8rem;
  max-height: 4.8rem;
  min-height: 4.8rem;

  & > span {
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    font-weight: bold;
    font-size: ${({ $extraSmall, $small, $medium }) =>
      $extraSmall ? "1rem" : $small ? "1.2rem" : $medium ? "1.6rem" : "2.2rem"};
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
  }
`;

const CustomIcon = (
  symbol: string,
  size?: "extraSmall" | "small" | "medium"
) => (
  <StyledCustomIcon
    $extraSmall
    $small={size === "small"}
    $medium={size === "medium"}
  >
    <CurrencyZone />
    <span>{symbol}</span>
  </StyledCustomIcon>
);

export const fiatIcons: { [k: string]: ReactNode } = {
  USD: <Usd />,
  EUR: <Eur />,
  UAH: CustomIcon("\u20B4"),
  INR: CustomIcon("\u20B9"),
  KRW: CustomIcon("\u20A9", "medium"),
  TRY: CustomIcon("\u20A4"),
  GBP: CustomIcon("\u00A3"),
  BRL: CustomIcon("R\u0024", "medium"),
  AUD: CustomIcon("A\u0024", "medium"),
  HKD: CustomIcon("HK\u0024", "small"),
  TWD: CustomIcon("NT\u0024", "small"),
  AMD: CustomIcon("\u058F"),
  KZT: CustomIcon("\u20B8"),
  PLN: CustomIcon("zł", "medium"),
  VND: CustomIcon("\u20AB"),
  SEK: CustomIcon("kr", "medium"),
  MDL: CustomIcon("L"),
  AZN: CustomIcon("\u20BC"),
  CAD: CustomIcon("C\u0024", "small"),
  DKK: CustomIcon("kr", "medium"),
  NOK: CustomIcon("kr", "medium"),
  HUF: CustomIcon("Ft", "medium"),
  RON: CustomIcon("lei", "small"),
  CZK: CustomIcon("Kč", "medium"),
  BGN: CustomIcon("ЛВ", "medium"),
  CHF: CustomIcon("\u20A3"),
  ILS: CustomIcon("\u20AA"),
  SAR: CustomIcon("\uFDFC", "medium"),
  BHD: CustomIcon("BD", "medium"),
  NZD: CustomIcon("NZ\u0024", "small"),
  MYR: CustomIcon("RM", "small"),
  ARS: CustomIcon("AR\u0024", "small"),
  // itez now unsupported ==>
  // RUB: CustomIcon("\u20BD"),
  // BYN: CustomIcon("p.", "medium"),
  SGD: CustomIcon("S\u0024", "medium"),
  // HRK: CustomIcon("kn", "medium"),

  //Paybis new fiats
  AED: CustomIcon("AED", "small"),
  BDT: CustomIcon("BDT", "small"),
  CLP: CustomIcon("CLP", "small"),
  COP: CustomIcon("COP", "small"),
  CRC: CustomIcon("CRC", "small"),
  DOP: CustomIcon("DOP", "small"),
  DZD: CustomIcon("DZD", "small"),
  EGP: CustomIcon("EGP", "small"),
  GEL: CustomIcon("GEL", "small"),
  JPY: CustomIcon("JPY", "small"),
  KWD: CustomIcon("KWD", "small"),
  MAD: CustomIcon("MAD", "small"),
  MXN: CustomIcon("MXN", "small"),
  NGN: CustomIcon("NGN", "small"),
  PEN: CustomIcon("PEN", "small"),
  PHP: CustomIcon("PHP", "small"),
  THB: CustomIcon("THB", "small"),
  TND: CustomIcon("TND", "small"),
  XAF: CustomIcon("XAF", "small"),
  ZAR: CustomIcon("ZAR", "small"),
  IDR: CustomIcon("IDR", "small"),
  KES: CustomIcon("KES", "small"),
  XOF: CustomIcon("XOF", "small"),
};

export const ALL_FIATS: ItezFiatOptionVariants | string[] = [
  "USD",
  "EUR",
  "UAH",
  "INR",
  "KRW",
  "TRY",
  "GBP",
  "BRL",
  "AUD",
  "HKD",
  "TWD",
  "AMD",
  "KZT",
  "PLN",
  "VND",
  "SEK",
  "MDL",
  "AZN",
  "CAD",
  "DKK",
  "NOK",
  "HUF",
  "RON",
  "CZK",
  "BGN",
  "CHF",
  "ILS",
  "SAR",
  "BHD",
  "NZD",
  "MYR",
  "ARS",

  // itez now unsupported ==>
  // "RUB",
  // "SGD",
  // "BYN",
  // "HRK",
];

export const FIAT_OPTIONS: ItezFiatOption[] = [
  {
    value: "USD",
    id: "usd",
    min: 50,
    max: 5000,
    icon: fiatIcons.USD,
  },
  {
    value: "EUR",
    id: "eur",
    min: 50,
    max: 5000,
    icon: fiatIcons.EUR,
  },
  {
    value: "ARS",
    id: "ars",
    min: 10300,
    max: 4000000,
    icon: fiatIcons.ARS,
  },
  {
    value: "AMD",
    id: "amd",
    min: 50000,
    max: 2000000,
    icon: fiatIcons.AMD,
  },
  {
    value: "AUD",
    id: "aud",
    min: 100,
    max: 8000,
    icon: fiatIcons.AUD,
  },
  {
    value: "AZN",
    id: "azn",
    min: 150,
    max: 9000,
    icon: fiatIcons.AZN,
  },
  {
    value: "BHD",
    id: "bhd",
    min: 30,
    max: 2000,
    icon: fiatIcons.BHD,
  },
  {
    value: "BRL",
    id: "brl",
    min: 500,
    max: 20000,
    icon: fiatIcons.BRL,
  },
  {
    value: "BGN",
    id: "bgn",
    min: 150,
    max: 9000,
    icon: fiatIcons.BGN,
  },
  {
    value: "CAD",
    id: "cad",
    min: 100,
    max: 6000,
    icon: fiatIcons.CAD,
  },
  {
    value: "CZK",
    id: "czk",
    min: 1500,
    max: 100000,
    icon: fiatIcons.CZK,
  },
  {
    value: "DKK",
    id: "dkk",
    min: 500,
    max: 30000,
    icon: fiatIcons.DKK,
  },
  {
    value: "HKD",
    id: "hkd",
    min: 500,
    max: 40000,
    icon: fiatIcons.HKD,
  },
  {
    value: "HUF",
    id: "huf",
    min: 12000,
    max: 1800000,
    icon: fiatIcons.HUF,
  },
  {
    value: "ILS",
    id: "ils",
    min: 200,
    max: 15000,
    icon: fiatIcons.ILS,
  },
  {
    value: "INR",
    id: "inr",
    min: 5000,
    max: 400000,
    icon: fiatIcons.INR,
  },
  {
    value: "KZT",
    id: "kzt",
    min: 40000,
    max: 2000000,
    icon: fiatIcons.KZT,
  },
  {
    value: "MYR",
    id: "myr",
    min: 250,
    max: 20000,
    icon: fiatIcons.MYR,
  },
  {
    value: "MDL",
    id: "mdl",
    min: 1000,
    max: 90000,
    icon: fiatIcons.MDL,
  },
  {
    value: "TWD",
    id: "twd",
    min: 2000,
    max: 150000,
    icon: fiatIcons.TWD,
  },
  {
    value: "NOK",
    id: "nok",
    min: 600,
    max: 45000,
    icon: fiatIcons.NOK,
  },
  {
    value: "NZD",
    id: "nzd",
    min: 100,
    max: 8000,
    icon: fiatIcons.NZD,
  },
  {
    value: "PLN",
    id: "pln",
    min: 300,
    max: 20000,
    icon: fiatIcons.PLN,
  },
  {
    value: "GBP",
    id: "gbp",
    min: 50,
    max: 4200,
    icon: fiatIcons.GBP,
  },
  {
    value: "RON",
    id: "ron",
    min: 300,
    max: 20000,
    icon: fiatIcons.RON,
  },
  {
    value: "SAR",
    id: "sar",
    min: 150,
    max: 20000,
    icon: fiatIcons.SAR,
  },
  {
    value: "KRW",
    id: "krw",
    min: 80000,
    max: 7000000,
    icon: fiatIcons.KRW,
  },
  {
    value: "SEK",
    id: "sek",
    min: 500,
    max: 50000,
    icon: fiatIcons.SEK,
  },
  {
    value: "CHF",
    id: "chf",
    min: 40,
    max: 4800,
    icon: fiatIcons.CHF,
  },
  {
    value: "TRY",
    id: "try",
    min: 600,
    max: 150000,
    icon: fiatIcons.TRY,
  },
  {
    value: "UAH",
    id: "uah",
    min: 2000,
    max: 150000,
    icon: fiatIcons.UAH,
  },
  {
    value: "VND",
    id: "vnd",
    min: 2000000,
    max: 130000000,
    icon: fiatIcons.VND,
  },
  // itez now unsupported ==>
  // {
  //   value: "RUB",
  //   id: "rub",
  //   min: 5000,
  //   max: 300000,
  //   icon: fiatIcons.RUB,
  // },
  // {
  //   value: "BYN",
  //   id: "blr",
  //   min: 200,
  //   max: 15000,
  //   icon: fiatIcons.BYN,
  // },
  // {
  //   value: "SGD",
  //   id: "sgd",
  //   min: 100,
  //   max: 5000,
  //   icon: fiatIcons.SGD,
  // },
  // {
  //   value: "HRK",
  //   id: "hrk",
  //   min: 500,
  //   max: 30000,
  //   icon: fiatIcons.HRK,
  // },
];
